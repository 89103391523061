/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  plasticSimIcc: '',
  isPlasticSim: false
});

export default function mobileReducer(state = INITIAL_STATE, action) {
  if (action.type === 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE') {
    if (action.payload.type === 'mobile') {
      const { name, value } = action.payload;
      if (name === 'isPlasticSim' && !value) {
        return state.set('isPlasticSim', false).set('plasticSimIcc', '');
      }
      return state.set(name, value);
    }
    return state;
  }
  return state;
}
