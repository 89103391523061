/** @format */
import { fromJS } from 'immutable';
import {
  REG_SCHEMA_RESET_ALL,
  REG_SCHEMA_RESET_STATUS,
  CREATE_REG_SCHEMA_REQUEST,
  CREATE_REG_SCHEMA_SUCCESS,
  CREATE_REG_SCHEMA_FAILURE,
  SEND_REG_SCHEMA_REQUEST,
  SEND_REG_SCHEMA_SUCCESS,
  SEND_REG_SCHEMA_FAILURE,
  GET_REG_SCHEMA_LIST_REQUEST,
  GET_REG_SCHEMA_LIST_SUCCESS,
  GET_REG_SCHEMA_LIST_FAILURE,
  GET_REG_SCHEMA_LIST_CHANGE_PAGE
} from 'actions/configuration/registrationSchema/actionTypes';

const initialState = fromJS({
  isLoading: false,
  error: null,
  status: null,
  data: null,
  page: null
});

const registrationSchema = (state = initialState, action) => {
  switch (action.type) {
    case REG_SCHEMA_RESET_ALL:
      return initialState;
    case REG_SCHEMA_RESET_STATUS:
      return state.set('status', null);
    case CREATE_REG_SCHEMA_REQUEST:
    case SEND_REG_SCHEMA_REQUEST:
    case GET_REG_SCHEMA_LIST_REQUEST:
      return state.set('isLoading', true);
    case CREATE_REG_SCHEMA_SUCCESS:
      return state
        .set('isLoading', false)
        .set('error', null)
        .set('status', 'created');
    case SEND_REG_SCHEMA_SUCCESS:
      return state
        .set('isLoading', false)
        .set('error', null)
        .set('status', 'sent');
    case CREATE_REG_SCHEMA_FAILURE:
    case SEND_REG_SCHEMA_FAILURE:
      return state
        .set('isLoading', false)
        .set('error', action.payload.msg)
        .set('status', 'error');
    case GET_REG_SCHEMA_LIST_CHANGE_PAGE: {
      return state.set('page', action.payload);
    }
    case GET_REG_SCHEMA_LIST_SUCCESS: {
      return state
        .set('isLoading', false)
        .set('error', null)
        .set('data', fromJS(action.payload));
    }
    case GET_REG_SCHEMA_LIST_FAILURE:
      return state
        .set('isLoading', false)
        .set('data', null)
        .set('error', action.payload.msg)
        .set('status', 'error');
    default:
      return state;
  }
};

export { registrationSchema };
