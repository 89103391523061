/** @format */

export const REG_SCHEMA_RESET_ALL = 'REG_SCHEMA_RESET_ALL';
export const CREATE_REG_SCHEMA_RESET = 'CREATE_REG_SCHEMA_RESET';
export const CREATE_REG_SCHEMA_REQUEST = 'CREATE_REG_SCHEMA_REQUEST';
export const CREATE_REG_SCHEMA_SUCCESS = 'CREATE_REG_SCHEMA_SUCCESS';
export const CREATE_REG_SCHEMA_FAILURE = 'CREATE_REG_SCHEMA_FAILURE';
export const SEND_REG_SCHEMA_RESET = 'SEND_REG_SCHEMA_RESET';
export const SEND_REG_SCHEMA_REQUEST = 'SEND_REG_SCHEMA_REQUEST';
export const SEND_REG_SCHEMA_SUCCESS = 'SEND_REG_SCHEMA_SUCCESS';
export const SEND_REG_SCHEMA_FAILURE = 'SEND_REG_SCHEMA_FAILURE';
export const GET_REG_SCHEMA_LIST_RESET = 'GET_REG_SCHEMA_LIST_RESET';
export const GET_REG_SCHEMA_LIST_REQUEST = 'GET_REG_SCHEMA_LIST_REQUEST';
export const GET_REG_SCHEMA_LIST_SUCCESS = 'GET_REG_SCHEMA_LIST_SUCCESS';
export const GET_REG_SCHEMA_LIST_FAILURE = 'GET_REG_SCHEMA_LIST_FAILURE';
export const REG_SCHEMA_RESET_STATUS = 'REG_SCHEMA_RESET_STATUS';
export const GET_REG_SCHEMA_LIST_CHANGE_PAGE =
  'GET_REG_SCHEMA_LIST_CHANGE_PAGE';
