/** @format */

import { fromJS, List, Map } from 'immutable';
import alarmCenters from './alarmCenters';
import inputs from './inputs';
import serialPort from './serialPort';
import errorInputs from './errorInputs';
import outputs from './outputs';
import relays from './relays';
import contactInformation from './contactInformation';
import inputReceiverMatrix, { sortMatrix } from './inputReceiverMatrix';
import receivers from './receivers';
import receiverSettings from './receiverSettings';
import remoteUnits from './remoteUnits';
import cameras from './cameras';
import lans from './lans';
import ipv6 from './ipv6';
import systemMessages from './systemMessages';
import bacnet from './bacnet';
import vds from './vds';
import util from 'utils/util';
import { notDalmAffectedFields } from './notDalmAffectedFields';

const INITIAL_STATE = fromJS({
  loading: false,
  error: '',
  configuration: getInitialConfiguration(),
  validationErrors: {},
  saving: false
});

export default function configurationReducer(state = INITIAL_STATE, action) {
  state = state.setIn(
    ['configuration', 'alarmCenters'],
    alarmCenters(state.getIn(['configuration', 'alarmCenters']), action)
  );
  state = state.setIn(
    ['configuration', 'inputs'],
    inputs(state.getIn(['configuration', 'inputs']), action)
  );
  state = state.setIn(
    ['configuration', 'serialPort'],
    serialPort(state.getIn(['configuration', 'serialPort']), action)
  );
  state = state.setIn(
    ['configuration', 'errorInputs'],
    errorInputs(state.getIn(['configuration', 'errorInputs']), action)
  );
  state = state.setIn(
    ['configuration', 'outputs'],
    outputs(state.getIn(['configuration', 'outputs']), action)
  );
  state = state.setIn(
    ['configuration', 'relays'],
    relays(state.getIn(['configuration', 'relays']), action)
  );
  state = state.setIn(
    ['configuration', 'contactInformation'],
    contactInformation(
      state.getIn(['configuration', 'contactInformation']),
      action
    )
  );
  state = state.setIn(
    ['configuration', 'inputReceiverMatrix'],
    inputReceiverMatrix(
      state.getIn(['configuration', 'inputReceiverMatrix']),
      action
    )
  );
  state = state.setIn(
    ['configuration', 'receivers'],
    receivers(state.getIn(['configuration', 'receivers']), action)
  );
  state = state.setIn(
    ['configuration', 'receiverSettings'],
    receiverSettings(state.getIn(['configuration', 'receiverSettings']), action)
  );
  state = state.setIn(
    ['configuration', 'remoteUnits'],
    remoteUnits(state.getIn(['configuration', 'remoteUnits']), action)
  );
  state = state.setIn(
    ['configuration', 'cameras'],
    cameras(state.getIn(['configuration', 'cameras']), action)
  );
  state = state.setIn(
    ['configuration', 'lans'],
    lans(state.getIn(['configuration', 'lans']), action)
  );
  state = state.setIn(
    ['configuration', 'ipv6'],
    ipv6(state.getIn(['configuration', 'ipv6']), action)
  );
  state = state.setIn(
    ['configuration', 'bacnet'],
    bacnet(state.getIn(['configuration', 'bacnet']), action)
  );
  state = state.setIn(
    ['configuration', 'systemMessages'],
    systemMessages(state.getIn(['configuration', 'systemMessages']), action)
  );
  state = state.setIn(
    ['configuration', 'vds'],
    vds(state.getIn(['configuration', 'vds']), action)
  );
  switch (action.type) {
    case 'CONFIGURATION_RESET_STATE':
      return INITIAL_STATE;
    case 'NEW_CONFIGURATION': {
      let merged = state.merge(INITIAL_STATE);
      action.payload.keep.forEach((path) => {
        merged = merged.setIn(path, state.getIn(path));
      });
      return merged.setIn(
        ['configuration', 'configurationCountry'],
        action.payload.country.get('isoCode')
      );
    }
    case 'LOAD_CONFIGURATION_STARTED': {
      return state.set('loading', true);
    }
    case 'LOAD_CONFIGURATION_DONE': {
      let configuration = fromJS(action.payload);
      configuration = configuration.set(
        'inputReceiverMatrix',
        configuration.get('inputReceiverMatrix').sort(sortMatrix)
      );

      return state
        .set('configuration', configuration)
        .set('loading', false)
        .setIn(
          ['configuration', 'alarmCenters'],
          alarmCenters(state.getIn(['configuration', 'alarmCenters']), action)
        )
        .setIn(
          ['configuration', 'lans'],
          lans(configuration.get('lans'), action)
        );
    }
    case 'LOAD_CONFIGURATION_FAILED':
    case 'ADD_FAVORITE_DALM_FAILED':
    case 'REMOVE_FAVORITE_DALM_FAILED': {
      return INITIAL_STATE.set('error', action.payload.msg).set(
        'loading',
        false
      );
    }
    case 'REMOVE_FAVORITE_DALM_SUCCESS': {
      return state.setIn(['configuration', 'isFavorite'], false);
    }
    case 'ADD_FAVORITE_DALM_SUCCESS': {
      return state.setIn(['configuration', 'isFavorite'], true);
    }
    case 'CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (isDisablingDhcp(action.payload)) {
        state = state.setIn(['configuration', 'dhcpName'], '');
      }

      if (
        !notDalmAffectedFields.includes(action.payload.name) &&
        !notDalmAffectedFields.includes(action.payload.type)
      ) {
        state = state
          .setIn(['configuration', 'isChanged'], true)
          .setIn(['configuration', 'lastUnsavedChange'], true);
      }
      if (
        action.payload.index !== undefined ||
        action.payload.type !== undefined
      ) {
        return state;
      } else if (action.payload.name === 'product') {
        state = state
          .setIn(['configuration', 'providerCode'], null)
          .setIn(['configuration', 'gprsType'], 'automatic')
          .setIn(['configuration', 'apnAuthentication'], null)
          .setIn(['configuration', 'apnUsername'], '')
          .setIn(['configuration', 'apnPassword'], '')
          .setIn(['configuration', 'dhcpName'], '')
          .setIn(['configuration', 'expansionBoard'], 'none')
          .setIn(['configuration', 'gprsDisabled'], 'gprs.enabled');
      } else if (
        action.payload.name === 'productType' &&
        action.payload.value === '4G'
      ) {
        state = state.setIn(
          ['configuration', 'firmwareInterval'],
          'from.3.3.1.0'
        );
        state = state.setIn(['configuration', 'ethernetSmtpServer'], '');
        state = state.setIn(['configuration', 'gprsSmtpServer'], '');
      }
      if (
        action.payload.name === 'simCardType' &&
        action.payload.value === 'dualtech'
      ) {
        state = state.setIn(['configuration', 'gprsSmtpServer'], '');
      } else if (
        action.payload.name === 'apnAuthentication' &&
        action.payload.value === 'none'
      ) {
        return state
          .setIn(['configuration', 'apnAuthentication'], null)
          .setIn(['configuration', 'apnUsername'], '')
          .setIn(['configuration', 'apnPassword'], '');
      }
      return state
        .setIn(['configuration', action.payload.name], action.payload.value)
        .setIn(['configuration', 'lastUnsavedChange'], true);
    }
    case 'SET_DEFAULT_PRODUCT': {
      return state.setIn(['configuration', 'product'], action.payload);
    }
    case 'SET_DEFAULT_DAAS': {
      const form = action.payload;
      return state
        .setIn(['configuration', 'isDaas'], true)
        .setIn(['configuration', 'product'], 'daas5000')
        .setIn(['configuration', 'activateEagleEye'], form.eagleEye)
        .setIn(['configuration', 'productType'], '4G')
        .setIn(['configuration', 'firmwareInterval'], 'from.3.3.1.0');
    }
    case 'SET_DEFAULT_EAGLE_EYE': {
      return state.setIn(['configuration', 'activateEagleEye'], action.payload);
    }
    case 'SET_DEFAULT_PRODUCT_TYPE': {
      return state
        .setIn(['configuration', 'firmwareInterval'], 'before.3.3.1.0')
        .setIn(['configuration', 'productType'], '2G/3G');
    }
    case 'CONFIGURATION_DALM_PHONE_NUMBER_CHANGE': {
      return state.setIn(['configuration', 'dalmPhoneNumber'], action.payload);
    }
    case 'SAVE_CONFIGURATION_DONE': {
      return state
        .set('saving', false)
        .setIn(['configuration', 'lastUnsavedChange'], false);
    }
    case 'SAVE_CONFIGURATION_STARTED': {
      return state.set('validationErrors', fromJS({})).set('saving', true);
    }
    case 'SAVE_CONFIGURATION_FAILED': {
      return state
        .set('validationErrors', fromJS(action.payload))
        .set('saving', false);
    }
    case 'CHANGE_PRODUCT_DONE': {
      return state
        .setIn(['configuration', 'product'], action.payload)
        .setIn(['configuration', 'isChanged'], true);
    }
    case 'CHANGE_DIALER_PROTOCOL': {
      return state
        .setIn(['configuration', 'dialerProtocol'], action.payload.value)
        .setIn(['configuration', 'isChanged'], true);
    }
    case 'COPIED_CONFIGURATION_DONE': {
      const oldAlarmCenters = state
        .getIn(['configuration', 'alarmCenters'])
        .map(
          (arc) =>
            arc &&
            (arc.get('hasDc09')
              ? arc
                  .set('commissionedAt', null)
                  .setIn(['dc09', 'encryptionKey'], '')
                  .setIn(['dc09', 'accountNumber'], '')
              : arc
                  .set('commissionedAt', null)
                  .setIn(['v4', 'transmitterCode'], '')
                  .setIn(['v4', 'authenticationCode'], ''))
        );

      const oldInputs = state
        .getIn(['configuration', 'inputs'])
        .map((input) => input.set('isActive', false));
      const oldOutputs = state
        .getIn(['configuration', 'outputs'])
        .map((output) => output.set('isActive', false));

      const deleteIdFrom = [
        'alarmCenters',
        'errorInputs',
        'inputs',
        'outputs',
        'relays',
        'cameras',
        'remoteUnits',
        'receivers',
        'contactInformation',
        'lans'
      ];

      const updatedState = state
        .setIn(['configuration', 'name'], '')
        .setIn(['configuration', 'status'], 'factory')
        .setIn(['configuration', 'billingPeriod'], null)
        .setIn(['configuration', 'dalmProgrammedAt'], null)
        .setIn(['configuration', 'alarmCenters'], oldAlarmCenters)
        .setIn(['configuration', 'inputs'], oldInputs)
        .setIn(['configuration', 'outputs'], oldOutputs);

      return deleteIdFrom.reduce(
        (acc, field) =>
          Map.isMap(acc.getIn(['configuration', field])) ||
          List.isList(acc.getIn(['configuration', field]))
            ? acc.setIn(
                ['configuration', field],
                acc.getIn(['configuration', field]).map((element) => {
                  if (element) {
                    return element
                      .delete('id')
                      .delete('configurationId')
                      .delete('createdAt')
                      .delete('updatedAt');
                  }
                  return element;
                })
              )
            : acc,
        updatedState
      );
    }
    case 'CHANGE_SERIAL_PORT': {
      if (!action.payload.get('hasDialerBrand')) {
        state = state.setIn(['configuration', 'dialerBrand'], 'none');
      }
      return state
        .setIn(['configuration', 'isChanged'], true)
        .setIn(['configuration', 'lastUnsavedChange'], true);
    }
    case 'CONFIGURATION_EDIT_INPUT_RECEIVER_MATRIX_CHANGE': {
      return state
        .setIn(['configuration', 'isChanged'], true)
        .setIn(['configuration', 'lastUnsavedChange'], true);
    }
    case 'CONFIGURATION_EDIT_CHANGE_ALARM_CENTER': {
      return state
        .setIn(['configuration', 'isChanged'], true)
        .setIn(['configuration', 'lastUnsavedChange'], true);
    }
    case 'CONFIGURATION_EDIT_TOGGLE_ALARM_CENTER_INPUT': {
      return state
        .setIn(['configuration', 'isChanged'], true)
        .setIn(['configuration', 'lastUnsavedChange'], true);
    }
    case 'ADD_PREDEFINED_ALARM_CENTERS': {
      return state
        .setIn(['configuration', 'isChanged'], true)
        .setIn(['configuration', 'lastUnsavedChange'], true);
    }
    case 'CHANGE_BILLING_PERIOD_DONE': {
      return state.setIn(['configuration', 'billingPeriod'], action.payload);
    }
    case 'EDIT_INVOICE_REFERENCE_DONE': {
      return state.setIn(
        ['configuration', 'invoiceReference'],
        action.payload.invoiceReference
      );
    }
    case 'EDIT_ADMIN_NOTES_DONE': {
      return state.setIn(['configuration', 'adminNotes'], action.payload);
    }
    default: {
      return state;
    }
  }
}

function isDisablingDhcp({ name, value, type, params }) {
  return (
    (name === 'networkType' &&
      value !== 'networkType.dhcp' &&
      params.ipv6Type !== 'dhcp') ||
    (name === 'type' &&
      value !== 'dhcp' &&
      type === 'ipv6' &&
      params.ipv4Type !== 'networkType.dhcp')
  );
}

function getInitialConfiguration() {
  return {
    name: '',
    isDaas: false,
    activateEagleEye: false,
    mqttOnline: false,
    freeText: '',
    invoiceReference: '',
    id: 0,
    createdAt: '',
    dalmUpdatedAt: '',
    folderId: 0,
    product: '',
    oem: 'standard',
    productType: null,
    firmwareInterval: null,
    inputs: inputs(undefined, { type: '' }),
    defaultGateway: '',
    networkType: 'networkType.dhcp',
    icmpDisabled: false,
    lineSpeed: 'auto',
    dns: '',
    netmask: '',
    ipAddress: '',
    ipv6: ipv6(undefined, { type: '' }),
    serialPort: serialPort(undefined, { type: '' }),
    dialerProtocol: 'None',
    dialerBrand: 'none',
    ethernetSmtpServer: '',
    gprsSmtpServer: '',
    dalmPhoneNumber: '',
    errorInputs: errorInputs(undefined, { type: '' }),
    systemMessages: systemMessages(undefined, { type: '' }),
    status: 'factory',
    alarmCenters: alarmCenters(undefined, { type: '' }),
    gprsApn: '',
    providerCode: '',
    simCardType: 'dualtech',
    gprsDisabled: 'gprs.enabled',
    outputs: outputs(undefined, { type: '' }),
    relays: relays(undefined, { type: '' }),
    outputPassword: util.generateCharacterString(8),
    noInstallationData: true,
    contactInformation: contactInformation(undefined, { type: '' }),
    country: {
      isoCode: 'OTHER',
      name: 'country.other',
      dc09: false,
      countryCode: '00'
    },
    inputReceiverMatrix: inputReceiverMatrix(undefined, { type: '' }),
    receivers: receivers(undefined, { type: '' }),
    receiverSettings: receiverSettings(undefined, { type: '' }),
    remoteUnits: remoteUnits(undefined, { type: '' }),
    cameras: cameras(undefined, { type: '' }),
    lans: lans(undefined, { type: '' }),
    bacnet: bacnet(undefined, { type: '' }),
    expansionBoard: 'none',
    connectionType: 'default',
    testModeStartDuration: null,
    testModeConfirmTimeout: null,
    testModeWarningTimeout: null,
    testModeExtensionDuration: null,
    isChanged: false,
    configurationCountry: 'OTHER',
    billingPeriod: null,
    vds: vds(undefined, { type: '' }),
    adminNotes: '',
    lastUnsavedChange: false,
    shares: []
  };
}
